import React, { useState, useEffect } from "react";
import { IconButton, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles(() => ({
  buttonTop: {
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    position: "fixed",
    right: "10px",
    bottom: "10px",
    fontSize: "2rem",
    zIndex: "1000",
    color: "white",
    backgroundColor: "#eb564a",
    width: 60,
    height: 60,
    display: "flex !important",
  },
}));

const ScrollButton = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <Fade in={visible}>
      <IconButton
        className={classes.buttonTop}
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}>
        <KeyboardArrowUpIcon fontSize="inherit" />
      </IconButton>
    </Fade>
  );
};

export default ScrollButton;
