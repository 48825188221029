import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  imageContainer: {
    width: "100%",
    aspectRatio: "9/14",
    overflow: "hidden",
    borderRadius: 5,
  },
  imagePoster: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 5,
    transition: "transform 0.3s ease",
  },
  imagePosterHover: {
    transform: "scale(1.05)",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(26, 26, 26, 0.6)",
    transition: "all 0.3s ease",
    borderRadius: 5,
  },
  detailContainer: {
    marginInline: -20,
    width: "max-content",
    maxWidth: 470,
    minWidth: 460,
    background: "#000000dd",
    boxShadow: "var(--xiclos-shadow)",
    borderRadius: "6px",
    transition: "all ease 300ms",
    padding: 15,
    gap: 5,
  },
});
