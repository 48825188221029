/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import useWidth from "../../../hooks/useWidth";
import { ASPECT_RATIO_POSTER } from "../../../common/constant";
import { trimString, scrollToTop } from "../../../common/Util";
import { useStyles } from "./styles";
import Loader from "../Loader";

interface Movie {
  mo_id: number;
  mo_title: string;
  mo_thumbnail: string;
  mo_image_fullsize: string;
  mo_description: string;
  mo_year: number;
  mo_imdb_rating: string;
  mo_mpa_rating: string;
  mo_duration: number;
  directors: string;
  genre: string;
  actors: string;
  countries: string;
  mo_title_metadata?: string;
  mo_description_metadata?: string;
}

interface PosterDetailProps {
  movie: Movie;
  usr: any;
  freeMode?: boolean;
  handleFavorite: (...args: any) => any;
  noLista?: boolean;
  onRemoveFavorite: (...args: any) => any;
  onAddFavorite: (...args: any) => any;
}

const PosterDetail = ({
  movie,
  freeMode,
  usr,
  handleFavorite,
  noLista,
  onAddFavorite,
  onRemoveFavorite,
}: PosterDetailProps) => {
  const width = useWidth();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const onNavigate = (movie: Movie) => {
    if (usr?.user) {
      if (freeMode) {
        history.push({
          pathname: "/watch-festival",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      } else {
        history.push({
          pathname: "/watch",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      }
    } else {
      if (freeMode) {
        history.push({
          pathname: "/watch-festival",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      } else {
        history.push("/login");
      }
    }
  };

  return (
    <div
      style={{
        width: "max-content",
        maxWidth: 500,
        minWidth: 480,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        top: " 0%",
        left: "0%",
        zIndex: 999,
        right: "auto",
        background: "#000000dd",
        boxShadow: "var(--xiclos-shadow)",
        borderRadius: "6px",
        transition: "all ease 300ms",
        padding: 15,
        gap: 20,
      }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            aspectRatio: ASPECT_RATIO_POSTER,
            borderRadius: 5,
          }}>
          <img
            style={{
              display: loading ? "none" : "block",
              width: 140,
              height: 220,
              objectFit: "cover",
              borderRadius: 5,
              transition: "all 0.3s ease",
              position: "relative",
            }}
            src={
              width !== "xs"
                ? movie.mo_thumbnail.replace("_150x260", "")
                : movie.mo_thumbnail
            }
            alt={movie.mo_title}
            onLoad={() => setLoading(false)}
          />
          <Loader show={loading} style={{ aspectRatio: ASPECT_RATIO_POSTER }} />
        </div>
        <div
          style={{
            paddingLeft: 10,
            display: "flex",
            width: "100%",
            flexDirection: "column",
            columnGap: 10,
            gap: 10,
          }}>
          <h2
            style={{
              fontFamily: "var(--xiclos-font-helv-bold)",
              fontWeight: 500,
              fontSize: "1.1em",
              textTransform: "uppercase",
              lineHeight: 1.2,
              color: "var(--iq-white)",
            }}>
            {movie.mo_title}
          </h2>
          <h3 className={classes.descriptionText}>
            <span>Origen </span>
            <span className={classes.descriptionItemText}>
              {movie.countries}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Directores </span>
            <span className={classes.descriptionItemText}>
              {trimString(movie.directors, 50)}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Reparto </span>
            <span className={classes.descriptionItemText}>
              {trimString(movie.actors, 50)}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Genero </span>
            <span className={classes.descriptionItemText}>
              {trimString(movie.genre, 50)}
            </span>
          </h3>

          <div className="row d-flex align-items-center mt-2">
            <div className="col-8 px-0">
              <button
                className="btn btn-hover vortex-description-see-movie"
                onClick={() => onNavigate(movie)}>
                <i className="fa fa-play mr-1" aria-hidden="true"></i>
                VER PELÍCULA
              </button>
            </div>
            <div className="col-4 px-0">
              <Link
                to={{
                  pathname: freeMode ? `/details-festival` : `/details`,
                  search: `?id=${movie.mo_id}`,
                  state: {
                    title: movie.mo_title_metadata
                      ? movie?.mo_title_metadata?.slice(0, 59)
                      : movie?.mo_title?.slice(0, 59),
                    description: movie.mo_description_metadata
                      ? movie?.mo_description_metadata?.slice(0, 159)
                      : movie?.mo_description?.slice(0, 159),
                  },
                }}>
                <button
                  onClick={() => scrollToTop()}
                  className="vortex-description-see-more">
                  Ver más
                </button>
              </Link>
            </div>
          </div>

          {noLista ? null : (
            <div className="row">
              {usr.user ? (
                handleFavorite(movie.mo_id) ? (
                  <div className="col-12 d-flex align-items-center px-0 mt-3">
                    <button
                      onClick={() => onRemoveFavorite(movie)}
                      className="vortex-description-add-fav d-flex justify-content-left align-items-center pl-2 py-1 pr-3">
                      <i className="ri-subtract-line pr-2"></i>
                      Eliminar de mi lista
                    </button>
                  </div>
                ) : (
                  <div className="col-12 d-flex align-items-center px-0 mt-3">
                    <button
                      onClick={() => onAddFavorite(movie)}
                      className="vortex-description-add-fav d-flex justify-content-left align-items-center pl-2 py-1 pr-3">
                      <i className="ri-add-line pr-2"></i>
                      Añadir a mi lista
                    </button>
                  </div>
                )
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div>
        <div>
          <h3 className={classes.descriptionText}>Sinopsis</h3>
          <p className={classes.sinopsisText}>{movie.mo_description}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            alignItems: "center",
          }}>
          <p className={classes.tagText}>{movie.mo_year}</p>
          <p className={classes.tagText}>{movie.mo_duration} min</p>
          <p className={`${classes.tagEdadText} ${classes.tagText}`}>
            {movie.mo_mpa_rating}
          </p>
          <div style={{ marginLeft: "auto" }}>
            <p className={`${classes.tagEdadText} ${classes.tagText}`}>
              IMDb {movie.mo_imdb_rating}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosterDetail;
