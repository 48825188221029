import { API, USE_MOCKS, HEADERS } from "./APIConnector";
import { MovieMock } from "./mocks/MovieMocks";
import { SliderMock } from "./mocks/SliderMocks";
import Firebase from "../config/Firebase";

//Consulta las movies de un slider
export const getMoviesBySliderId = async (id, offset, limit, moviesNoIds) => {
  // console.log("SERVICE GET MOVIES BY SLIDERS ID")
  if (USE_MOCKS) {
    let movies = [];
    for (let i = 0; i < limit; i++) {
      movies.push({ ...MovieMock, mo_id: offset + i + 1 });
    }
    // console.log("USE MOCK", id, offset, limit, movies)
    return { data: movies };
  }
  let mnids = "";
  if (moviesNoIds && moviesNoIds.length > 0) {
    mnids = "&mnid=" + moviesNoIds.join("&mnid=");
  }
  const endpoint = `${API}/slider?id=${id}&limitMovies=${limit}&offsetMovies=${offset}${mnids}`;
  // console.log("GET MOVIES BY SLIDERS ", endpoint)
  const data = await fetch(endpoint, { method: "GET", headers: HEADERS() });
  const result = await data.json();
  return result.data[0];
};

function trimString(string, length) {
  if (string.length > length) {
    let arrayString = string
      .substring(0, length - 4)
      .trim()
      .split(" ");
    return arrayString.slice(0, arrayString.length - 1).join(" ") + " ...";
  } else {
    return string;
  }
}

//Trae y construye el slider las películas que estuvo viendo
export const getSliderByUserWatching = async signal => {
  const userId = JSON.parse(localStorage.getItem("U")).us_id;
  if (userId == undefined) {
    // console.error('EL USUARIO NO SE CARGÓ CORRECTAMENTE')
    return false;
  }
  try {
    const endpoint = `${API}/movies/progress?userId=${userId}`;
    const resp = await fetch(endpoint, {
      method: "GET",
      headers: HEADERS(),
      signal,
    });
    const result = await resp.json();

    let data = result.data;
    if (data !== null || data != false) {
      let slider = {
        sl_id: 0,
        sl_name: "Continuar viendo",
        count_movies: 0,
        movies: [],
      };

      for (let i = 0; i < data.length; i++) {
        if (
          parseInt(data[i].mp_progress) >
          parseInt(data[i].Movie.mo_duration) * 60 * 0.02
        ) {
          let movie = { ...data[i].Movie };
          if (movie.actor) {
            let actors = ``;
            movie.actor.forEach(el => {
              actors += `${el.ac_name} ${el.ac_lastname}, `;
            });
            movie.actors = trimString(
              actors.substring(0, actors.length - 2),
              63,
            );
          }
          if (movie.directors) {
            let directors = ``;
            movie.directors.forEach(el => {
              directors += `${el.di_name} ${el.di_lastname}, `;
            });
            movie.directors = trimString(
              directors.substring(0, directors.length - 2),
              63,
            );
          }
          if (movie.genre) {
            let genre = ``;
            movie.genre.forEach(el => {
              genre += `${el.ge_name}, `;
            });
            movie.genre = trimString(genre.substring(0, genre.length - 2), 33);
          }
          if (movie.country) {
            let countries = ``;
            movie.country.forEach(el => {
              countries += `${el.co_name}, `;
            });
            movie.countries = trimString(
              countries.substring(0, countries.length - 2),
              33,
            );
          }
          slider.count_movies++;
          slider.movies.push(movie);
        }
      }

      return slider;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getAllSliders = async (
  offset,
  limit,
  filter = { videoclub: 0 },
  limitMovies = 12,
  orderMovies = "",
) => {
  if (USE_MOCKS) {
    let sliders = [];
    for (let i = 0; i < 6; i++) {
      sliders.push({ ...SliderMock, sl_id: i + 1 });
    }
    return { data: sliders };
  }
  const endpoint = `${API}/slider?videoclub=${filter.videoclub}&limit=${limit}&offset=${offset}&limitMovies=${limitMovies}&orderMovies=${orderMovies}&offsetMovies=0&order=ASC`;
  const data = await fetch(endpoint, { method: "GET", headers: HEADERS() });
  return data.json();
};

export const getAllSlidersWithoutLimit = async () => {
  const data = await fetch(`${API}/all-sliders`, {
    method: "GET",
    headers: HEADERS(),
  });
  return await data.json();
};

export const deleteSlider = async arrayIds => {
  const data = await fetch(`${API}/delete/slider`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      sl_id: arrayIds,
    }),
  });
  return await data.json();
};

export const deleteSliderImage = async imageName => {
  const storageRef = await Firebase.getStorageRef();

  const fileRef = await storageRef.child(`/sliders/${imageName}`);

  await fileRef.delete();
};

export const fetchInitUploadSliders = async () => {
  const response = await fetch(`${API}/init_upload_sliders?edit=true`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  return await response.json();
};

export const fetchMoviesBySliderId = async id => {
  const response = await fetch(`${API}/slider2/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  return await response.json();
};

export const getHomeSliders = async signal => {
  const data = await fetch(`${API}/v2/home/sliders?limitMovies=12`, {
    method: "GET",
    headers: HEADERS(),
    signal,
    cache: "default",
  });
  const result = await data.json();
  return result.data;
};

export const getMovieSliders = async ({ id, limit, offset, order }) => {
  const data = await fetch(
    `${API}/v2/home/movies/${id}?limit=${limit}&offset=${offset}&order=${order}`,
    {
      method: "GET",
      headers: HEADERS(),
    },
  );
  const result = await data.json();
  return result.data;
};
