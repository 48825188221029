import React, { Fragment, useState, useEffect, useCallback } from "react";
import { getSubscriptions } from "../provider/SubscriptionService";
import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import LoadingMsgModal from "../components/LoadingMsgModal";
import Footer from "../components/Footer";
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import ModalMui from "../components/ModalMui";
import { makeStyles } from "@mui/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModalConfirmation from "../components/ModalConfirmation";
import { deleteSubscription } from "../provider/SubscriptionService";
import { SUBSCRIPTIONS } from "../common/constant";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  modalInfoBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  table: {
    minWidth: 1200,
  },
  tableHeaders: {
    fontWeight: 800,
  },
});

const EditSubscriptions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [subscription, setSubscription] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    message: "",
    open: false,
    callBack: () => {},
  });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    message: "",
  });

  const errorFetchSubscriptions = useCallback(() => {
    setInfoModal(prevState => ({
      ...prevState,
      open: true,
      message: "Error al conseguir las suscripciones",
      callBack: () => handleCloseInfoModal(),
    }));
  }, []);

  useEffect(() => {
    isLoading("Cargando suscripciones...");
    getSubscriptions()
      .then(data => {
        if (data.code === 200) {
          setSubscriptions(data.data);
          isNotLoading();
        } else {
          isNotLoading();
          errorFetchSubscriptions();
        }
      })
      .catch(err => {
        console.log(err);
        isNotLoading();
        errorFetchSubscriptions();
      });
  }, [errorFetchSubscriptions]);

  const isLoading = (message = "Cargando...") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      callBack: () => {},
    }));
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "¿Esta seguro que desea eliminar la suscripción?",
      };
    });
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(prevState => {
      return {
        ...prevState,
        open: false,
        message: "",
      };
    });
  };

  const handleDeleteSubscription = async () => {
    handleCloseConfirmationModal();
    isLoading();
    const response = await deleteSubscription(subscription?.su_id);
    isNotLoading();
    if (response.code === 200) {
      const refreshSubscriptions = subscriptions.filter(sub => {
        return sub.su_id !== subscription?.su_id;
      });
      setSubscriptions(refreshSubscriptions);
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Eliminado con éxito",
        callBack: () => handleCloseInfoModal(),
      }));
    } else {
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Error al eliminar la suscripción",
        callBack: () => handleCloseInfoModal(),
      }));
    }
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Ver suscripciones"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalConfirmation
        open={confirmationModal.open}
        onClose={handleCloseConfirmationModal}
        message={confirmationModal.message}
        onConfirmation={handleDeleteSubscription}
        onCancel={handleCloseConfirmationModal}
      />

      <ModalMui open={infoModal.open} handleClose={() => {}} maxWidth="xs">
        <Box className={classes.modalInfoBox}>
          <Typography variant="h6" style={{ color: "black" }}>
            {infoModal.message}
          </Typography>
        </Box>
        <Box className={classes.modalInfoBox}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={infoModal.callBack}>
            Aceptar
          </Button>
        </Box>
      </ModalMui>

      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={12} lg={11} xl={10}>
          <Paper className={classes.paperContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaders} align="center">
                      Acciones
                    </TableCell>
                    <TableCell className={classes.tableHeaders}>
                      Descripción
                    </TableCell>
                    <TableCell className={classes.tableHeaders}>
                      Código Cupón
                    </TableCell>
                    <TableCell className={classes.tableHeaders} align="center">
                      Precio ARS
                    </TableCell>
                    <TableCell className={classes.tableHeaders} align="center">
                      Precio USD
                    </TableCell>
                    <TableCell className={classes.tableHeaders}>
                      Código MercadoPago
                    </TableCell>
                    <TableCell className={classes.tableHeaders}>
                      Código PayPal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptions.map(row => (
                    <TableRow key={row.su_id}>
                      <TableCell align="center" component="th" scope="row">
                        {row.su_name === SUBSCRIPTIONS.general || row.su_name === SUBSCRIPTIONS.exclusivo ? (
                          <IconButton size="small" disabled>
                            <DeleteForeverIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            style={{
                              color: theme.palette.error.main,
                            }}
                            onClick={() => {
                              setSubscription(row);
                              handleOpenConfirmationModal();
                            }}>
                            <DeleteForeverIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.su_description}
                      </TableCell>
                      <TableCell>{row.su_name}</TableCell>
                      <TableCell align="center">$ {row.su_price_ars}</TableCell>
                      <TableCell align="center">$ {row.su_price_usd}</TableCell>
                      <TableCell>{row.su_mp_code}</TableCell>
                      <TableCell>{row.su_pay_code}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default EditSubscriptions;
