/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Director } from "../../constants";
import "@splidejs/react-splide/css";
import { useHistory } from "react-router-dom";
import { useStyle } from "./styles";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../../../../common/constant";

export interface CarouselProps {
  initialItems: Director[];
}

const DirectorCarousel: React.FC<CarouselProps> = ({ initialItems }) => {
  const classes = useStyle();
  const history = useHistory();
  const [directors, setDirectors] = useState<Director[]>(initialItems);
  const splideRef = useRef<Splide>(null);
  const isDesktop = useMediaQuery(BREAKPOINTS.desktop);

  useEffect(() => {
    setDirectors(initialItems);
  }, [initialItems]);

  return (
    <Splide
      ref={splideRef}
      className={classes.carouselContainer}
      options={{
        type: "slide",
        perPage: 6,
        perMove: 5,
        gap: "1.2rem",
        pagination: false,
        arrows: isDesktop ? true : false,
        padding: { left: "2rem", right: "2rem" },
        breakpoints: {
          320: {
            perPage: 2,
            perMove: 1,
            arrows: false,
            padding: { left: "0.3rem", right: "0.8rem" },
            gap: "0.5rem",
          },
          599: {
            perPage: 3,
            perMove: 2,
            arrows: false,
            padding: { left: "0.3rem", right: "0.8rem" },
            gap: "0.5rem",
          },
          899: {
            perPage: 4,
            perMove: 3,
          },
          1199: {
            perPage: 6,
            perMove: 5,
          },
          1535: {
            perPage: 7,
            perMove: 6,
          },
          3200: {
            perPage: 8,
            perMove: 7,
          },
        },
      }}>
      {directors.map(director => (
        <SplideSlide key={director.di_id}>
          <img
            src={director.di_img_link}
            alt="director"
            onClick={() => history.push("/cine-club")}
            className={classes.image}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default DirectorCarousel;
