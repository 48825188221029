/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { trimString } from "../../../../common/Util";
import { Movie } from "../../constants";
import Loader from "../../../../components/V2/Loader";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addMovieListAction,
  removeMovieListAction,
} from "../../../../actions/MyListAction";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../../../../common/constant";

interface PosterDetailsCarouselProps {
  movie: Movie;
  freeMode?: boolean;
  handleModal: (type: string) => any;
}

const PosterDetail = ({
  movie,
  freeMode,
  handleModal,
}: PosterDetailsCarouselProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);
  const history = useHistory();
  const classes = useStyles();
  const usr = useSelector((state: any) => state.usr);
  const myList = useSelector((state: any) => state.ml);
  const [loading, setLoading] = useState(true);

  const isInMyList = (id: number) => {
    const isInList = myList.movies.filter((mov: any) => {
      if (mov.mo_id === id) {
        return mov;
      } else {
        return false;
      }
    });
    if (isInList.length === 0) {
      return false;
    }
    return true;
  };

  const onAddFavorite = (mov: any) => {
    dispatch(
      addMovieListAction(
        mov,
        () => handleModal("add"),
        () => handleModal("errorAdd"),
      ),
    );
  };

  const onRemoveFavorite = (mov: any) => {
    dispatch(
      removeMovieListAction(
        mov,
        () => handleModal("remove"),
        () => handleModal("errorRemove"),
      ),
    );
  };

  const onNavigateWatch = (movie: Movie) => {
    if (usr?.user) {
      if (freeMode) {
        history.push({
          pathname: "/watch-festival",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      } else {
        history.push({
          pathname: "/watch",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      }
    } else {
      if (freeMode) {
        history.push({
          pathname: "/watch-festival",
          search: `?id=${movie.mo_id}&title=${movie.mo_title}`,
        });
      } else {
        history.push("/login");
      }
    }
  };

  const onNavigateDetail = (movie: Movie) => {
    if (freeMode) {
      history.push({
        pathname: "/details-festival",
        search: `?id=${movie.mo_id}`,
      });
    } else {
      history.push({
        pathname: "/details",
        search: `?id=${movie.mo_id}`,
      });
    }
  };

  const handleStrings = (type: string, movie: Movie) => {
    let countries = "";
    let actors = "";
    let directors = "";
    let genres = "";
    switch (type) {
      case "country":
        movie.countries.forEach(country => {
          if (countries === "") {
            countries = country.co_name;
          } else {
            countries = `${countries}, ${country.co_name}`;
          }
        });
        return trimString(countries, 50);
      case "actor":
        movie.actors.forEach(actor => {
          if (actors === "") {
            actors = `${actor.ac_name} ${actor.ac_lastname}`;
          } else {
            actors = `${actors}, ${actor.ac_name} ${actor.ac_lastname}`;
          }
        });
        return trimString(actors, 75);
      case "director":
        movie.directors.forEach(director => {
          if (directors === "") {
            directors = `${director.di_name} ${director.di_lastname}`;
          } else {
            directors = `${directors}, ${director.di_name} ${director.di_lastname}`;
          }
        });
        return trimString(directors, 75);
      case "genre":
        movie.genres.forEach(genre => {
          if (genres === "") {
            genres = genre.ge_name;
          } else {
            genres = `${genres}, ${genre.ge_name}`;
          }
        });
        return trimString(genres, 75);
      default:
        break;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <div className={classes.imageContainer}>
          <img
            style={{ display: loading ? "none" : "block" }}
            className={classes.image}
            src={
              isMobile
                ? movie.mo_thumbnail.replace("_150x260", "")
                : movie.mo_thumbnail
            }
            alt={movie.mo_title}
            onLoad={() => setLoading(false)}
          />
          <Loader show={loading} className={classes.loader} />
        </div>
        <div className={classes.infoContainer}>
          <h2 className={classes.titleText}>{movie.mo_title}</h2>
          <h3 className={classes.descriptionText}>
            <span>Origen </span>
            <span className={classes.descriptionItemText}>
              {handleStrings("country", movie)}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Directores </span>
            <span className={classes.descriptionItemText}>
              {handleStrings("director", movie)}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Reparto </span>
            <span className={classes.descriptionItemText}>
              {handleStrings("actor", movie)}
            </span>
          </h3>
          <h3 className={classes.descriptionText}>
            <span>Genero </span>
            <span className={classes.descriptionItemText}>
              {handleStrings("genre", movie)}
            </span>
          </h3>

          <div className={classes.buttonContainer}>
            <div>
              <button
                className={classes.buttonWatchMovie}
                onClick={() => onNavigateWatch(movie)}>
                <i className="fa fa-play mr-1" aria-hidden="true"></i>
                VER PELÍCULA
              </button>
            </div>
            <div>
              <button
                className={classes.buttonWatchDetail}
                onClick={() => onNavigateDetail(movie)}>
                Ver más
              </button>
            </div>
          </div>

          {freeMode ? null : (
            <div style={{ paddingTop: 5 }}>
              {usr.user ? (
                isInMyList(movie.mo_id) ? (
                  <div>
                    <button
                      onClick={() => onRemoveFavorite(movie)}
                      className={classes.buttonFavorite}>
                      <i className="ri-subtract-line pr-2"></i>
                      Eliminar de mi lista
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => onAddFavorite(movie)}
                      className={classes.buttonFavorite}>
                      <i className="ri-add-line pr-2"></i>
                      Añadir a mi lista
                    </button>
                  </div>
                )
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div>
        <div>
          <h3 className={classes.descriptionText}>Sinopsis</h3>
          <p className={classes.sinopsisText}>
            {trimString(movie.mo_description, 250)}
          </p>
        </div>
        <div className={classes.bottomInfoContainer}>
          <p className={classes.tagText}>{movie.mo_year}</p>
          <p className={classes.tagText}>{movie.mo_duration} min</p>
          <p className={`${classes.tagEdadText} ${classes.tagText}`}>
            {movie.mo_mpa_rating}
          </p>
          <div style={{ marginLeft: "auto" }}>
            <p className={`${classes.tagEdadText} ${classes.tagText}`}>
              IMDb {movie.mo_imdb_rating}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosterDetail;
