import React, { useEffect, useState } from "react";

import "../css/cine-club.scss";

import { useDispatch, useSelector } from "react-redux";
import { getMyList } from "../provider/MyListServices";
import { listAction } from "../actions/MyListAction";
import { fetchCineClubSliders } from "../actions/CineClubActions";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import NavigationTitle from "../components/NavigationTitle";
import SliderPull from "../components/SliderPull";
import DirectorHeader from "../components/DirectorHeader";
import ScrollButton from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const CineClub = () => {
  const ml = useSelector(state => state.ml);
  const sliders = useSelector(state => state.cineClub.sliders);
  const [errorFetch, setErrorFetch] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (sliders.length === 0) {
      window.scrollTo(0, 0);
    }
  }, [sliders.length]);

  // TODO: Quitar esto
  useEffect(() => {
    getMyList().then(result => {
      if (result.count_movies !== ml.count_movies) {
        dispatch(listAction(result));
      }
    });
  }, [dispatch, ml.count_movies]);

  useEffect(() => {
    if (sliders.length === 0) {
      dispatch(
        fetchCineClubSliders({
          videoclub: 1,
          cbFail: () => setErrorFetch(true),
        }),
      );
    }
  }, [dispatch, sliders.length]);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = action => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrio un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrio un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="cine-club" id="cineclub">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cineclub - Xiclos</title>
        <meta
          name="description"
          content="Cineclub - Xiclos de cine con los mejores del séptimo arte."
        />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Cineclub"}
        icon={"../images/icons/xiclos-icon-yellow@2x.png"}
        descriptionText={
          "Películas seleccionadas en Cineclub. Xiclos de cine con los mejores del séptimo arte."
        }
      />

      <div
        id="cineclub-sections"
        className="wrapper-movie-interest"
        style={{ paddingTop: "4rem" }}>
        {errorFetch && (
          <div id="message" className="row" style={{ paddingBottom: "3rem" }}>
            <div className="col-12 d-flex justify-content-start align-items-center">
              <p
                style={{
                  fontFamily: "Abel",
                  paddingLeft: "5em",
                  marginTop: "3em !important",
                }}>
                Algo salió mal, vuelva a intentarlo
              </p>
            </div>
          </div>
        )}

        {sliders.map(slider => (
          <section
            className="show-movie-detail-section-tras"
            id={`vortex-cineclub-${slider.sl_id}`}
            key={`vortex-cineclub-${slider.sl_id}`}>
            <div
              className="container-fluid"
              style={{ padding: "0px 0 0px", backgroundColor: "#2d2d2d" }}>
              <DirectorHeader movie={slider} />
              <SliderPull
                id={"vortex-recommend"}
                slider={slider}
                handleModal={handleModal}
                snackHandler={setSnackState}
              />
            </div>
          </section>
        ))}

        {sliders.length === 0 && !errorFetch && (
          <div className="divLoading">
            <Loader show />
          </div>
        )}
      </div>
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CineClub;
