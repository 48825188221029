export const HEADERS = () => {
  const user = localStorage.getItem("U");
  const token = localStorage.getItem("Authorization");
  if (user) {
    return {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      U: user,
    };
  }
  return { "Access-Control-Allow-Origin": "*" };
};

export const HeadersBearer = () => {
  const user = localStorage.getItem("U");
  const token = localStorage.getItem("Authorization");
  if (user) {
    return {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      U: user,
    };
  }
  return { "Access-Control-Allow-Origin": "*" };
};

export const USE_MOCKS = false;
export const API = "https://us-central1-xiclos-prod.cloudfunctions.net/api/api";
export const CDN_JWPLAYER = "https://cdn.jwplayer.com/v2/media";
