/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Banner } from "../../constants";
import "@splidejs/react-splide/css";
import { useStyle } from "./styles";
import useWidth from "../../../../hooks/useWidth";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../../../../common/constant";

export interface CarouselProps {
  initialItems: Banner[];
}

const BannerCarousel: React.FC<CarouselProps> = ({ initialItems }) => {
  const splideRef = useRef<Splide>(null);
  const classes = useStyle();
  const width = useWidth();
  const isDesktop = useMediaQuery(BREAKPOINTS.desktop);

  const [Banners, setBanners] = useState<Banner[]>(initialItems);
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight,
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setBanners(initialItems);
  }, [initialItems]);

  const bannerTitleTextStyle = () => {
    return width === "xs" || width === "sm"
      ? { fontSize: "6vh" }
      : width === "md"
      ? { fontSize: "7vh" }
      : width === "lg"
      ? { fontSize: "8vh", paddingTop: "10%" }
      : { fontSize: "8.8vh", paddingTop: "10%" };
  };

  // TODO: Quitar el CSS y meter todo en styles

  return (
    <Splide
      className={classes.carouselContainer}
      ref={splideRef}
      options={{
        speed: 800,
        type: "slide",
        perPage: 1,
        perMove: 1,
        pagination: isDesktop ? true : false,
        arrows: isDesktop ? true : false,
        autoplay: true,
        rewind: true,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      }}>
      {Banners.map(banner => (
        <SplideSlide key={banner.bn_id}>
          <div
            className={`banner-component`}
            style={{
              backgroundImage: `url(${banner.bn_image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              height: isDesktop ? viewportHeight - 78 : 400,
            }}>
            {isDesktop ? (
              <div
                className={classes.bannerShadow}
                style={{ position: "absolute", zIndex: 1 }}
              />
            ) : (
              <div
                className={classes.mobileBannerShadow}
                style={{ position: "absolute", zIndex: 1 }}
              />
            )}
            <div className="container-fluid h-100">
              <div className="slider-inner h-100">
                <div className="row align-items-center  h-100">
                  <div className="col-xl-6 col-lg-6 col-md-12 text-container">
                    <h1
                      className={
                        width === "xs" || width === "sm"
                          ? "slider-text text-uppercase"
                          : "slider-text title text-uppercase home-slider-movie-title title-home-banners"
                      }
                      style={{
                        ...bannerTitleTextStyle(),
                        position: "relative",
                        zIndex: 2,
                      }}
                      data-animation-in="fadeInLeft">
                      {banner.bn_name}
                    </h1>
                    {width === "xs" || width === "sm" ? null : (
                      <div style={{ position: "relative", zIndex: 2 }}>
                        {banner.bn_description
                          .split("<br />")
                          .map((text, index) => {
                            return (
                              <p
                                className="p-home-banners"
                                data-animation-in="fadeInUp"
                                data-delay-in="0.7"
                                key={index}>
                                {text}
                                <br />
                              </p>
                            );
                          })}
                      </div>
                    )}
                    <div
                      className="d-flex align-items-center r-mb-23"
                      data-animation-in="fadeInUp"
                      data-delay-in="1">
                      <a href={banner.bn_link} className="btn btn-hover">
                        {banner.bn_button_name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default BannerCarousel;
