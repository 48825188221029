/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction } from "../../actions/MyListAction";
import { scrollToTop } from "../../common/Util";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import DirectorCarousel from "../../components/DirectorCarousel";
import { LoaderApp } from "../../components/Loader";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ScrollButton from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import {
  ACT_IS_LOADING_APP,
  ACT_IS_NOT_LOADING_APP,
} from "../../actions/ActionTypes";
import { getHomeSliders } from "../../provider/SliderServices";
import { getBanners } from "../../provider/BannerServices";
import { getDirectors } from "../../provider/DirectorService";
import XiclosYellow from "../../images/icons/xiclos-icon-yellow@3x.png";
import XiclosRed from "../../images/icons/xiclos-icon-red@3x.png";
import { useStyle } from "./styles";
import SliderCarousel from "./components/SliderCarousel";
import { getMovieSliders } from "../../provider/SliderServices";
import { Movie, Slider, Director } from "./constants";
import { getMyList, getContinueWatching } from "../../provider/MyListServices";
import BannerCarousel from "./components/BannerCarousel";
import DirectorCarousel from "./components/DirectorCarousel";

const Home = () => {
  const classes = useStyle();
  const myList = useSelector((state: any) => state.ml);
  const usr = useSelector((state: any) => state.usr);
  const sl = useSelector((state: any) => state.sl);
  const bn = useSelector((state: any) => state.bn);

  const loadingApp = useSelector((state: any) => state.loading);
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();

  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [banners, setBanners] = useState([]);
  const [sliders, setSliders] = useState<Slider[]>([]);
  const [directors, setDirectors] = useState<Director[]>([]);

  const [continueWatching, setContinueWatching] = useState<Movie[]>([]);

  useEffect(function toTop() {
    scrollToTop();
  }, []);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();
    dispatch({ type: ACT_IS_LOADING_APP });

    const initHome = async () => {
      try {
        const [resultsDirectors] = await Promise.all([getDirectors()]);

        let resultBanners = [];
        const bannersLocalStorage = localStorage.getItem("banners");
        const bannersLSParsed = JSON.parse(
          bannersLocalStorage ? bannersLocalStorage : "[]",
        );
        if (bn.firstFetch || bannersLSParsed?.length <= 0) {
          resultBanners = await getBanners(abortController.signal);
        }

        let resultsSliders = [];
        const slidersLocalStorage = localStorage.getItem("sliders");
        const slidersLSParsed = JSON.parse(
          slidersLocalStorage ? slidersLocalStorage : "[]",
        );

        if (sl.firstFetch || slidersLSParsed?.length <= 0) {
          resultsSliders = await getHomeSliders(abortController.signal);
        }

        if (mounted) {
          setDirectors(resultsDirectors || []);
          if (bn.firstFetch || bannersLSParsed?.length <= 0) {
            setBanners(resultBanners || []);
            dispatch({ type: "ACT_BANNER_FIRST_FETCH" });
            localStorage.setItem("banners", JSON.stringify(bannersLSParsed));
          } else {
            setBanners(bannersLSParsed || []);
          }

          if (sl.firstFetch || slidersLSParsed?.length <= 0) {
            setSliders(resultsSliders || []);
            dispatch({ type: "ACT_SLIDER_FIRST_FETCH" });
            localStorage.setItem("sliders", JSON.stringify(resultsSliders));
          } else {
            setSliders(slidersLSParsed || []);
          }
          setIsFetching(false);
        }
        dispatch({ type: ACT_IS_NOT_LOADING_APP });
      } catch (errInitHome: unknown) {
        if ((errInitHome as Error)?.name === "AbortError") {
          console.log("Fetch aborted");
          return;
        }
        setIsFetching(false);
        console.log("error init Home", errInitHome);
      }
    };

    initHome();

    return () => {
      mounted = false;
      abortController.abort(); // Cancela las peticiones pendientes
    };
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    const initContinueWatching = async () => {
      const result = await getContinueWatching(abortController.signal);
      if (result && mounted) {
        setContinueWatching(result);
      }
    };

    if (usr.user) {
      initContinueWatching();
    }

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [usr.user]);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    const initGetMyList = async () => {
      const result = await getMyList(abortController.signal);

      if (result && mounted) {
        dispatch(listAction(result));
      }
    };

    if (usr.user) {
      initGetMyList();
    }

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [dispatch, usr.user]);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const fetchMoreMovies = async (
    offset: number,
    id: number,
  ): Promise<Movie[]> => {
    try {
      const newMovies = await getMovieSliders({
        id: id,
        limit: 12,
        offset: offset,
        order: "ASC",
      });
      return newMovies;
    } catch (error) {
      console.error("Error fetching items:", error);
      return [];
    }
  };

  const handleModal = (action: string) => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Xiclos</title>
        <meta name="description" content="Xiclos - Saber mirar" />
      </Helmet>

      <Header />
      <section>
        {banners && banners.length > 0 && (
          <BannerCarousel initialItems={banners} />
        )}
      </section>

      {continueWatching && continueWatching.length > 0 && (
        <div className={classes.continuarViendoBg}>
          <div className={classes.titleContainer}>
            <img
              src={XiclosRed}
              className={classes.titleIconContainer}
              alt=""
            />
            <h3 className={classes.titleSlider}>Continuar viendo</h3>
          </div>

          <SliderCarousel
            initialItems={continueWatching}
            handleModal={handleModal}
          />
        </div>
      )}

      {myList && myList?.movies?.length > 0 && (
        <div className={classes.miListBg}>
          <div className={classes.titleContainer}>
            <img
              src={XiclosRed}
              className={classes.titleIconContainer}
              alt=""
            />
            <h3 className={classes.titleSlider}>Mi Lista</h3>
          </div>

          <SliderCarousel
            initialItems={myList.movies}
            handleModal={handleModal}
          />
        </div>
      )}
      <div>
        {sliders.length > 0 &&
          sliders.map((slider, index) => (
            <Fragment key={slider.sl_id}>
              {index === 6 && directors && directors.length > 0 && (
                <>
                  <div className={classes.titleContainer}>
                    <img
                      src={XiclosYellow}
                      className={classes.titleIconContainer}
                      alt=""
                    />
                    <h3 className={classes.titleSlider}>CINECLUB</h3>
                  </div>
                  <DirectorCarousel initialItems={directors} />
                </>
              )}
              <div
                className={
                  index === 0
                    ? classes.yellowBg
                    : index === 1
                    ? classes.redBg
                    : index % 2 === 0
                    ? classes.defaultBg
                    : classes.defaultDegradeBg
                }>
                <div className={classes.titleContainer}>
                  <img
                    src={
                      index === 0
                        ? XiclosRed
                        : index > 6
                        ? XiclosRed
                        : XiclosYellow
                    }
                    className={classes.titleIconContainer}
                    alt=""
                  />
                  <h3 className={classes.titleSlider}>{slider.sl_name}</h3>
                </div>
                <SliderCarousel
                  initialItems={slider.movies}
                  fetchMoreItems={fetchMoreMovies}
                  sliderId={slider.sl_id}
                  handleModal={handleModal}
                />
              </div>
            </Fragment>
          ))}
      </div>
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type as any}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
      <LoaderApp open={Boolean(loadingApp.isLoading || isFetching)} />
    </div>
  );
};

export default Home;
