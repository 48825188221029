import { API, USE_MOCKS, HEADERS } from "./APIConnector";
import { DirectorListMock } from "./mocks/DirectorMocks";

// TODO Consulta los directores
export const getDirectors = async () => {
  // console.log("SERVICE GET DIRECTORS")
  // if(USE_MOCKS){
  return DirectorListMock.data;
  // }
  // const endpoint = `${API}/directors`
  // const data = await fetch(endpoint, {method:"GET", headers: HEADERS()})
  // const result = await data.json()
  // return result.data
};
