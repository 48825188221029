import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  continuarViendoBg: {
    background:
      "linear-gradient(to right, #1a1a1a 0%, #343433 49%, #1a1a1a 100%)",
  },
  miListBg: {
    background: "#343433",
  },
  yellowBg: {
    backgroundImage:
      "linear-gradient(to right, #8e8839 0%,#c4bb4f 49%, #8e8839 100%)",
  },
  redBg: {
    backgroundImage: `linear-gradient(
    to right,
    #732323 0%,
    var(--xiclos-red) 49%,
    #732323 100%
  )`,
  },
  defaultDegradeBg: {
    backgroundImage: `linear-gradient(to right, #1a1a1a 0%, #343433 49%, #1a1a1a 100%)`,
  },
  defaultBg: {
    background: "#343433",
  },
  titleSlider: {
    color: "#FFFFFF",
    fontFamily: "Abel",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "capitalize",
    fontSize: "1.8em",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    paddingInline: 35,
    paddingTop: 30,
  },
  titleIconContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 2,
    height: 30,
  },
});
