import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { validatePassword } from "../common/Util";
import Footer from "../components/Footer";
import Header from "../components/Header";
import XiclosCyan from "../images/icons/xiclos-icon-cyan@3x.png";

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  activeSubscription,
  cancelSubscription,
  changePassword,
  setUser,
} from "../provider/ProfileServices";

import Loader, { LoaderApp } from "../components/Loader";
import { STATUS_USER_FREE, STATUS_USER_INACTIVE } from "../common/constant";
import { useDispatch } from "react-redux";
import { loginAction } from "../actions/UserActions";
import Firebase from "../config/Firebase";
import PaymentForm from "../components/PaymentForm";

import NavigationTitle from "../components/NavigationTitle";
import ModalMui from "../components/ModalMui";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    padding: "10px 20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "20px 0",
    },
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  modal: {
    "& .MuiPaper-root": {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        margin: "10px",
      },
      "& .MuiDialogTitle-root": {
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
      },

      "& .MuiDialogContentText-root": {
        [theme.breakpoints.down("xs")]: {
          marginBottom: "15px",
        },
      },
    },
    "& h2": {
      color: "var(--xiclos-bg)",
      fontFamily: "var(--xiclos-font-helv-bold)",
    },
  },
  grid: {
    padding: "20px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 5px",
    },
  },
  gridItem: {
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  colorText: {
    color: "var(--xiclos-text)",
  },
  fontFamilyBold: {
    fontFamily: "var(--xiclos-font-helv-bold)",
  },
  fontFamilyNormal: {
    fontFamily: "var(--xiclos-font-helv-normal)",
  },
  font1em: {
    fontSize: "1em",
  },
  gridItemTitle: {
    color: "var(--xiclos-bg)",
    fontFamily: "var(--xiclos-font-helv-normal)",
  },
  gridItemValue: {
    color: "var(--xiclos-text)",
    fontFamily: "var(--xiclos-font-helv-bold)",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    textAlign: "center",
    borderRadius: "5px",
    padding: theme.spacing(2, 4, 3),
    "& #transition-modal-title": {
      color: "var(--xiclos-red)",
      fontSize: "20px",
      fontFamily: "var(--xiclos-font-helv-bold)",
    },
    "& #transition-modal-description": {
      color: "var(--xiclos-text)",
      paddingTop: "10px",
      margin: 0,
    },
  },
  paperContainer: {
    padding: "30px 20px 10px 20px",
  },
  container: {
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 40,
  },
}));

function Profile() {
  const history = useHistory();
  let usr = useSelector(state => state.usr);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userObject, setUserObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errors, setErrors] = useState({
    pass: { msg: "", status: false },
    newPass: { msg: "", status: false },
    resultPass: { msg: "", status: false },
    sub: { msg: "", status: false },
    method: { msg: "", status: false },
    activeSub: { msg: "", status: false },
  });
  const [openErrorActiveSub, setOpenErrorActiveSub] = useState({
    open: false,
    loading: false,
    msg: "",
  });
  const [openChangePassword, setOpenChangePassword] = useState({
    open: false,
    loading: false,
    result: false,
  });
  const [openCancelSubscription, setOpenCancelSubscription] = useState({
    open: false,
    loading: false,
    result: false,
    cb: () => {},
  });
  const [openActiveSubscription, setOpenActiveSubscription] = useState({
    open: false,
    loading: false,
    result: false,
    cb: () => {},
  });
  const [openChangeMethod, setOpenChangeMethod] = useState({
    open: false,
    result: { status: false, code: 0, msg: "", title: "" },
  });

  useEffect(() => {
    if (usr.checked && usr.user === null) {
      history.push("/home");
    }
  }, [history, usr.checked, usr.user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUser({})
      .then(obj => {
        setUserObject({ ...obj });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleCloseErrorActiveSubscription = () => {
    setOpenErrorActiveSub(prevState => {
      return { ...prevState, open: false, msg: "" };
    });
    setOpenChangeMethod(state => ({
      ...state,
      open: true,
      result: { status: false, code: 0, msg: "", title: "" },
    }));
  };

  const handleClosePassword = () => {
    setOpenChangePassword(state => ({ ...state, open: false }));
  };

  const handleCloseSubscription = () => {
    setOpenCancelSubscription(state => ({ ...state, open: false }));
  };

  const handleCloseActiveSubscription = () => {
    setOpenActiveSubscription(state => ({ ...state, open: false }));
  };

  const handleCloseMethod = () => {
    setOpenChangeMethod(state => ({ ...state, open: false }));
  };

  const handleBack = () => {
    setOpenChangePassword(state => ({
      ...state,
      open: true,
      result: false,
      loading: false,
    }));
    setErrors(state => ({
      ...state,
      resultPass: { msg: "", status: false },
    }));
  };

  const handleChangePassword = async () => {
    let validateNewPassword = validatePassword(newPassword);

    let errs = false;
    if (!validateNewPassword) {
      errs = true;
      setErrors(errors => ({
        ...errors,
        newPass: {
          msg: "La contraseña debe tener al menos un número, una letra y como mínimo 8 caracteres",
          status: true,
        },
      }));
    }
    if (newPassword !== newPassword2) {
      errs = true;
      setErrors(errs => ({
        ...errs,
        newPass: { msg: "La contraseñas no son iguales", status: true },
      }));
    }
    if (newPassword === oldPassword) {
      errs = true;
      setErrors(errs => ({
        ...errs,
        newPass: {
          msg: "La contraseña actual y la nueva son iguales",
          status: true,
        },
      }));
    }

    if (errs) {
      return null;
    } else {
      setOpenChangePassword(state => ({ ...state, loading: true }));
      try {
        let err = await changePassword(oldPassword, newPassword);
        if (err) {
          setOpenChangePassword(state => ({ ...state, loading: false }));
          setErrors(errs => ({
            ...errs,
            pass: { msg: "La contraseña es incorrecta", status: true },
          }));
        } else {
          setOpenChangePassword(state => ({
            ...state,
            loading: false,
            result: true,
          }));
          setErrors(errs => ({
            ...errs,
            pass: { status: false, msg: "" },
            newPass: { status: false, msg: "" },
            resultPass: { status: false, msg: "" },
          }));
        }
      } catch (err) {
        setErrors(errs => ({
          ...errs,
          pass: { status: false, msg: "" },
          newPass: { status: false, msg: "" },
          resultPass: { status: true, msg: "Algo salió mal" },
        }));
        setOpenChangePassword(state => ({
          ...state,
          loading: false,
          result: true,
        }));
      }
    }
  };

  const handleCancelSubscription = async () => {
    setOpenCancelSubscription(state => ({ ...state, loading: true }));
    try {
      let err = await cancelSubscription();
      if (err) {
        setOpenCancelSubscription(state => ({
          ...state,
          loading: false,
          result: true,
        }));
        setErrors(errs => ({
          ...errs,
          sub: { status: true, msg: "Algo salió mal" },
        }));
      } else {
        setErrors(errs => ({ ...errs, sub: { status: false, msg: "" } }));
        setOpenCancelSubscription(state => ({
          ...state,
          loading: false,
          result: true,
          cb: () => {
            dispatch(loginAction(Firebase.currentUser()));
            setUser(userObject, true).then(obj => {
              setUserObject({ ...obj });
            });
          },
        }));
      }
    } catch (err) {
      setErrors(errs => ({
        ...errs,
        sub: { status: true, msg: "Algo salió mal" },
      }));
      setOpenCancelSubscription(state => ({
        ...state,
        loading: false,
        result: true,
      }));
    }
  };

  const handleActiveSubscription = async () => {
    setOpenActiveSubscription(state => ({ ...state, loading: true }));
    try {
      let response = await activeSubscription();
      if (response.error && response.error.type === "cancelled") {
        setOpenActiveSubscription(state => ({
          ...state,
          open: false,
        }));
        setOpenErrorActiveSub(prevState => {
          return {
            ...prevState,
            open: true,
            msg: `Su suscripción esta actualmente cancelada, deberá realizar una nueva 
            con su tarjeta anterior o con una nueva para continuar.`,
          };
        });
      } else if (!response.data) {
        setOpenActiveSubscription(state => ({
          ...state,
          loading: false,
          result: true,
        }));
        setErrors(errs => ({
          ...errs,
          sub: { status: true, msg: "Algo salió mal" },
        }));
      } else {
        setErrors(errs => ({ ...errs, sub: { status: false, msg: "" } }));
        setOpenActiveSubscription(state => ({
          ...state,
          loading: false,
          result: true,
          cb: () => {
            dispatch(loginAction(Firebase.currentUser()));
            setUser(userObject, true).then(obj => {
              setUserObject({ ...obj });
            });
          },
        }));
      }
    } catch (err) {
      setErrors(errs => ({
        ...errs,
        sub: { status: true, msg: "Algo salió mal" },
      }));
      setOpenActiveSubscription(state => ({
        ...state,
        loading: false,
        result: true,
      }));
    }
  };

  const handleChangeMethod = async (err, result) => {
    if (err) {
      setOpenChangeMethod(state => ({
        ...state,
        result: { status: true, msg: "", code: 503 },
      }));
      setErrors(errs => ({
        ...errs,
        method: { status: true, msg: "Algo salió mal" },
      }));
    } else {
      let { status, msg, title } = result;
      setOpenChangeMethod(state => ({
        ...state,
        result: { status: true, msg, code: status, title },
      }));
      setErrors(errs => ({ ...errs, method: { status: false, msg: "" } }));
    }
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mi cuenta - Xiclos</title>
        <meta name="description" content="Perfil del usuario" />
      </Helmet>
      <ModalMui
        open={openErrorActiveSub.open}
        maxWidth="xs"
        close
        handleClose={handleCloseErrorActiveSubscription}>
        <Grid container item justifyContent="center">
          <Grid item xs={12}>
            <Typography
              style={{ color: "black", marginTop: 25, marginBottom: 20 }}
              variant="h6">
              {openErrorActiveSub.msg}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              disableElevation
              onClick={handleCloseErrorActiveSubscription}
              fullWidth
              color="primary">
              Continuar
            </Button>
          </Grid>
        </Grid>
      </ModalMui>

      <Header />
      <NavigationTitle title={"Mi Cuenta"} icon={XiclosCyan} />
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12} md={11} lg={10} xl={9}>
          <Paper className={classes.paperContainer}>
            <div>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-text)",
                }}>
                Mi Perfil
              </Typography>
              <Grid container className={classes.grid}>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Nombre
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Apellido
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.lastname}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    País
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.country}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Provincia
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.state}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Localidad
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.city}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridItem} sm={2}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Cód. Postal
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.zipcode}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <hr style={{ border: "1px solid rgba(0,0,0,.2)", margin: 0 }} />
            <div style={{ padding: "30px 20px 10px 20px" }}>
              <Grid container className={classes.grid}>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Email
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    {userObject.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemTitle} ${classes.font1em}`}>
                    Contraseña
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.gridItemValue} ${classes.font1em}`}>
                    *****************
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                  <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      setOpenChangePassword(state => ({
                        ...state,
                        open: true,
                        result: false,
                        loading: false,
                      }));
                    }}
                    disabled={openChangePassword.open}
                    className={classes.button}>
                    Cambiar contraseña
                  </Button>
                </Grid>
              </Grid>
            </div>
            <hr style={{ border: "1px solid rgba(0,0,0,.2)", margin: 0 }} />
            <div style={{ padding: "30px 20px 10px 20px" }}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-text)",
                }}>
                Membresía
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-red)",
                }}>
                {userObject.membership}
              </Typography>
              <Grid container className={classes.grid}>
                <Grid item xs={12} sm={8} className={classes.gridItem}>
                  <Typography
                    variant="caption"
                    className={classes.gridItemTitle}>
                    Estado de la membresía
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.colorText} ${classes.fontFamilyNormal}`}>
                    <span className={classes.gridItemValue}>
                      <span>{userObject.status} </span>
                      {usr.user &&
                        usr.user.us_suscription_status &&
                        usr.user.us_suscription_status !==
                          STATUS_USER_INACTIVE &&
                        usr.user.us_suscription_status !== STATUS_USER_FREE && (
                          <>
                            (<span>{userObject.amount}</span>).{" "}
                            <span className={classes.fontFamilyNormal}>
                              El próximo cobro será el{" "}
                              <span>{userObject.nextPayment}</span>
                            </span>
                          </>
                        )}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                  {usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status !== STATUS_USER_INACTIVE &&
                    usr.user.us_suscription_status !== STATUS_USER_FREE &&
                    userObject &&
                    userObject.subscription && (
                      <Button
                        disableElevation
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          setOpenCancelSubscription(state => ({
                            ...state,
                            open: true,
                            result: false,
                            loading: false,
                          }));
                        }}
                        disabled={openCancelSubscription.open}
                        className={classes.button}>
                        Cancelar mi Suscripción
                      </Button>
                    )}
                  {usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status === STATUS_USER_INACTIVE &&
                    userObject &&
                    userObject.subscription && (
                      <Button
                        disableElevation
                        color="secondary"
                        variant="outlined"
                        disabled={openActiveSubscription.open}
                        onClick={() => {
                          setOpenActiveSubscription(state => ({
                            ...state,
                            open: true,
                            result: false,
                            loading: false,
                          }));
                        }}
                        className={classes.button}>
                        Activar mi Suscripción
                      </Button>
                    )}
                </Grid>
              </Grid>
              <Grid container className={classes.grid}>
                <Grid item xs={12} sm={8} className={classes.gridItem}>
                  {usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status !== STATUS_USER_INACTIVE &&
                    usr.user.us_suscription_status !== STATUS_USER_FREE && (
                      <>
                        <Typography
                          variant="caption"
                          className={classes.gridItemTitle}>
                          Método de pago
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.gridItemValue}>
                          {userObject.method}
                        </Typography>
                      </>
                    )}
                </Grid>
                <Grid item xs={12} sm={4} className={classes.gridItem}>
                  {userObject &&
                    userObject.subscription &&
                    userObject.method === "MercadoPago" &&
                    usr.user &&
                    usr.user.us_suscription_status !== STATUS_USER_FREE && (
                      <Button
                        disableElevation
                        color="primary"
                        variant="contained"
                        disabled={openChangeMethod.open}
                        onClick={() => {
                          setOpenChangeMethod(state => ({
                            ...state,
                            open: true,
                            result: { ...state.result, status: false },
                          }));
                        }}
                        className={classes.button}>
                        Cambiar método de pago
                      </Button>
                    )}
                  {userObject &&
                    !userObject.subscription &&
                    usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status !== STATUS_USER_INACTIVE &&
                    usr.user.us_suscription_status !== STATUS_USER_FREE &&
                    userObject.method !== "Paypal" && (
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle2"
                        className={classes.gridItemTitle}>
                        Deberás comunicarte a <b>info@xiclos.com</b> para{" "}
                        <b>Cambiar tu método de pago</b> o{" "}
                        <b>Cancelar tu subscripción</b>
                      </Typography>
                    )}
                  {userObject &&
                    !userObject.subscription &&
                    usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status !== STATUS_USER_INACTIVE &&
                    usr.user.us_suscription_status !== STATUS_USER_FREE &&
                    userObject.method === "Paypal" && (
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle2"
                        className={classes.gridItemTitle}>
                        Si quieres modificar tu medio de pago, deberás hacerlo
                        desde Paypal. Si tienes algún inconveniente puedes
                        comunicarte a <b>info@xiclos.com</b>
                      </Typography>
                    )}
                  {userObject &&
                    !userObject.subscription &&
                    usr.user &&
                    usr.user.us_suscription_status &&
                    usr.user.us_suscription_status === STATUS_USER_INACTIVE && (
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle2"
                        className={classes.gridItemTitle}>
                        Deberás comunicarte a <b>info@xiclos.com</b> para{" "}
                        <b>Activar tu suscripción</b>
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        disableEnforceFocus
        disableScrollLock
        open={openChangePassword.open}
        className={classes.modal}
        onClose={() => {
          if (openChangePassword.result) {
            handleClosePassword();
          }
        }}
        aria-labelledby="form-change-password">
        {openChangePassword.loading && (
          <DialogContent style={{ height: 250, width: 250 }}>
            <Loader show={openChangePassword.loading} />
          </DialogContent>
        )}
        {!openChangePassword.loading && !openChangePassword.result && (
          <>
            <DialogTitle id="form-change-password">
              Cambiar contraseña
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para cambiar la contraseña necesitará completar los siguientes
                campos:
              </DialogContentText>
              <TextField
                autoFocus
                error={errors.pass.status}
                helperText={errors.pass.status && errors.pass.msg}
                margin="dense"
                value={oldPassword}
                id="oldPassword"
                onChange={e => {
                  setOldPassword(e.target.value);
                  setErrors(errs => ({
                    ...errs,
                    pass: { msg: "", status: false },
                  }));
                }}
                label="Contraseña anterior"
                type="password"
                fullWidth
              />
              <TextField
                margin="dense"
                error={errors.newPass.status}
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                  setErrors(errs => ({
                    ...errs,
                    newPass: { msg: "", status: false },
                  }));
                }}
                id="newPassword1"
                label="Contraseña nueva"
                type="password"
                fullWidth
              />
              <TextField
                margin="dense"
                error={errors.newPass.status}
                onChange={e => {
                  setNewPassword2(e.target.value);
                  setErrors(errs => ({
                    ...errs,
                    newPass: { msg: "", status: false },
                  }));
                }}
                value={newPassword2}
                id="newPassword2"
                label="Repetir contraseña nueva"
                type="password"
                fullWidth
                helperText={errors.newPass.status && errors.newPass.msg}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePassword}
                disableElevation
                fullWidth
                color="primary">
                Volver
              </Button>
              <Button
                onClick={handleChangePassword}
                fullWidth
                disableElevation
                color="primary"
                variant="contained">
                Cambiar
              </Button>
            </DialogActions>
          </>
        )}
        {!openChangePassword.loading &&
          openChangePassword.result &&
          !errors.resultPass.status && (
            <>
              <DialogTitle>La contraseña ha sido modificada</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  La contraseña fue modificada con éxito, recuerda no
                  compartirla
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClosePassword}
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )}
        {!openChangePassword.loading &&
          openChangePassword.result &&
          errors.resultPass.status && (
            <>
              <DialogTitle>Algo salió mal</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Algo salió mal, no pudimos modificar la contraseña, intentalo
                  mas tarde
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClosePassword}
                  disableElevation
                  fullWidth
                  color="primary">
                  Cerrar
                </Button>
                <Button
                  disableElevation
                  onClick={handleBack}
                  fullWidth
                  variant="contained"
                  color="primary">
                  Volver
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      <Dialog
        disableEnforceFocus
        disableScrollLock
        open={openCancelSubscription.open}
        className={classes.modal}
        onClose={handleCloseSubscription}
        aria-labelledby="form-cancel-subscription">
        {openCancelSubscription.loading && (
          <DialogContent style={{ height: 250, width: 250 }}>
            <Loader show={openCancelSubscription.loading} />
          </DialogContent>
        )}
        {!openCancelSubscription.loading && !openCancelSubscription.result && (
          <>
            <DialogTitle id="form-cancel-subscription">
              Cancelar mi suscripción
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Está seguro que desea cancelar su suscripción?
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={handleCloseSubscription}
                disableElevation
                color="primary">
                Volver
              </Button>
              <Button
                disableElevation
                onClick={handleCancelSubscription}
                variant="contained"
                color="primary">
                Cancelar mi suscripción
              </Button>
            </DialogActions>
          </>
        )}
        {!openCancelSubscription.loading &&
          openCancelSubscription.result &&
          !errors.sub.status && (
            <>
              <DialogTitle>La subscripción ha sido cancelada</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  La subscripción fue cancelada con éxito, recuerda que siempre
                  puedes volver a activarla
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={() => {
                    handleCloseSubscription();
                    openCancelSubscription.cb && openCancelSubscription.cb();
                  }}
                  fullWidth
                  variant="contained"
                  color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )}
        {!openCancelSubscription.loading &&
          openCancelSubscription.result &&
          errors.sub.status && (
            <>
              <DialogTitle>Algo salió mal</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Algo salió mal, no pudimos cancelar tu subscripción, intentalo
                  mas tarde
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={handleCloseSubscription}
                  fullWidth
                  color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      <Dialog
        disableEnforceFocus
        disableScrollLock
        open={openActiveSubscription.open}
        className={classes.modal}
        onClose={handleCloseActiveSubscription}
        aria-labelledby="form-cancel-subscription">
        {openActiveSubscription.loading && (
          <DialogContent style={{ height: 250, width: 250 }}>
            <Loader show={openActiveSubscription.loading} />
          </DialogContent>
        )}
        {!openActiveSubscription.loading && !openActiveSubscription.result && (
          <>
            <DialogTitle id="form-cancel-subscription">
              Activar mi suscripción
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Se debitará todos los meses a partir de hoy el importe de{" "}
                <b>{userObject.amount}</b> en la tarjeta que habías asociado. Si
                quieres modificar el método de pago y activar la suscripción,
                haz click en <b>Volver</b> y luego en el botón{" "}
                <b>Cambiar método de pago</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={handleCloseActiveSubscription}
                disableElevation
                color="primary">
                Volver
              </Button>
              <Button
                disableElevation
                onClick={handleActiveSubscription}
                variant="contained"
                color="primary">
                Activar mi suscripción
              </Button>
            </DialogActions>
          </>
        )}
        {!openActiveSubscription.loading &&
          openActiveSubscription.result &&
          !errors.sub.status && (
            <>
              <DialogTitle>La subscripción ha sido activada!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Recuerda que se debitará todos los meses a partir de hoy el
                  importe de <b>{userObject.amount}</b> en la tarjeta que habías
                  asociado. Si quieres modificar el método de pago haz click en
                  el botón <b>Cambiar método de pago</b>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={() => {
                    handleCloseActiveSubscription();
                    openActiveSubscription.cb && openActiveSubscription.cb();
                  }}
                  fullWidth
                  variant="contained"
                  color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )}
        {!openActiveSubscription.loading &&
          openActiveSubscription.result &&
          errors.sub.status && (
            <>
              <DialogTitle>Algo salió mal</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Algo salió mal, no pudimos activar tu subscripción, intentalo
                  mas tarde
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={handleCloseActiveSubscription}
                  fullWidth
                  color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      <Dialog
        disableEnforceFocus
        disableScrollLock
        open={openChangeMethod.open}
        className={classes.modal}
        onClose={handleCloseMethod}
        aria-labelledby="form-change-method"
        maxWidth="md">
        {!openChangeMethod.result.status && (
          <>
            <DialogTitle id="form-cancel-subscription">
              Cambiar método de pago
            </DialogTitle>
            {userObject.method !== "MercadoPago" && (
              <>
                <DialogContent>
                  <DialogContentText>
                    Aún no está desarrollado para Paypal u otro medio de pago
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    disableElevation
                    onClick={handleCloseMethod}
                    fullWidth
                    variant="contained"
                    color="primary">
                    Cerrar
                  </Button>
                </DialogActions>
              </>
            )}
            {userObject.method === "MercadoPago" && (
              <>
                <DialogContent className={classes.dialogContent}>
                  <PaymentForm
                    method="MercadoPago"
                    user={userObject}
                    submit={(errs, values) => {
                      handleChangeMethod(errs, values);
                    }}
                    cancel={handleCloseMethod}
                  />
                </DialogContent>
              </>
            )}
          </>
        )}
        {openChangeMethod.result.status && !errors.method.status && (
          <>
            <DialogTitle>{openChangeMethod.result.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {openChangeMethod.result.msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disableElevation
                onClick={e => {
                  handleCloseMethod(e);
                  dispatch(loginAction(Firebase.currentUser()));
                  setUser(userObject, true).then(obj => {
                    setUserObject({ ...obj });
                  });
                }}
                fullWidth
                variant="contained"
                color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
        {openChangeMethod.result.status && errors.method.status && (
          <>
            <DialogTitle>Algo salió mal</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Algo salió mal, no pudimos cambiar tu método de pago, intentalo
                mas tarde
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseMethod}
                disableElevation
                fullWidth
                color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <LoaderApp open={loading} />
      <Footer />
    </Fragment>
  );
}

export default Profile;
