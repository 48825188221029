/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ACT_SLIDER_CHANGE,
  ACT_SLIDER_COUNT,
  ACT_SLIDER_DIRECTORS,
  ACT_SLIDER_INIT,
  ACT_SLIDER_UPDATE,
} from "../actions/ActionTypes";

const initialState = {
  loadingStaticSliders: true,
  loadingSliders: true,
  staticSliders: [],
  sliders: [],
  countSliders: 0,
  directors: [],
  firstFetch: true,
};

export default function (
  state = initialState,
  action: {
    type: string;
    newSliders: any;
    directors: any;
    sliders: any;
    countSliders: any;
  },
) {
  if (action.type === ACT_SLIDER_CHANGE) {
    return Object.assign({
      ...state,
      sliders: [...state.sliders, ...action.newSliders],
    });
  }

  if (action.type === ACT_SLIDER_DIRECTORS) {
    return { ...state, directors: action.directors };
  }

  if (action.type === ACT_SLIDER_INIT) {
    return { ...state, sliders: action.sliders };
  }

  if (action.type === ACT_SLIDER_COUNT) {
    return { ...state, countSliders: action.countSliders };
  }

  if (action.type === ACT_SLIDER_UPDATE) {
    return { ...state, sliders: action.sliders };
  }

  if (action.type === "ACT_SLIDER_FIRST_FETCH") {
    return { ...state, firstFetch: false };
  }

  return state;
}
