import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles(() => ({
  carouselContainer: {
    paddingTop: 30,
    paddingBottom: 40,
    "& .splide__arrow": {
      backgroundColor: "#eb564a",
      width: "3rem",
      height: "3rem",
      opacity: 1,
      "&:hover": {
        backgroundColor: "#FFF",
        "& svg": {
          fill: "#000",
        },
      },

      "& svg": {
        fill: "#FFF",
        width: "1.2rem",
        height: "1.2rem",
      },

      "&:disabled": {
        backgroundColor: "#ccc",
        opacity: 0.5,
        "& svg": {
          fill: "#999 !important",
        },
      },
    },
    transform: "translateZ(0)",
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
}));
