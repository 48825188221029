import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles(() => ({
  carouselContainer: {
    "& .splide__pagination": {
      "& .is-active": {
        background: "#eb564a",
      },
    },
    "& .splide__arrow": {
      backgroundColor: "#eb564a",
      width: "3rem",
      height: "3rem",
      opacity: 1,
      "&:hover": {
        backgroundColor: "#FFF",
        "& svg": {
          fill: "#000",
        },
      },

      "& svg": {
        fill: "#FFF",
        width: "1.2rem",
        height: "1.2rem",
      },
    },
    transform: "translateZ(0)",
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
  bannerShadow: {
    background: `linear-gradient(
      70deg,
      rgba(0, 0, 0, 80%) 35%,
      rgba(20, 20, 20, 92%) 35%,
      rgba(83, 100, 141, 0) 0%
    )`,
    width: "100%",
    zIndex: 1,
    height: "100%",
  },
  mobileBannerShadow: {
    background: "rgba(0,0,0,.4)",
    width: "100%",
    zIndex: 1,
    height: "100%",
  },
}));
