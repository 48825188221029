import {
  ACT_USER_CHANGE,
  ACT_IS_ADMIN_FALSE,
  ACT_IS_ADMIN_TRUE,
  ACT_IS_SEO_TRUE,
  ACT_IS_SEO_FALSE,
} from "./ActionTypes";
import Firebase from "../config/Firebase";
import history from "../common/history";
import { ROLES } from "../common/constant";

import { getUserData, verifyFreeUserExpDate } from "../provider/UserService";

export function loginAction(userProvided, checkUser, unCheckUser, callback) {
  return dispatch => {
    let forceRefresh = true;
    userProvided.getIdToken(forceRefresh).then(token => {
      localStorage.setItem("Authorization", token);
      if (localStorage.getItem("U")) {
        let user = JSON.parse(localStorage.getItem("U"));
        localStorage.setItem("is", "checked");
        getUserData(userProvided)
          .then(data => {
            user = data.data;
            if (
              parseInt(data.data.us_id) !== parseInt(user.us_id) ||
              data.data.us_suscription_status !== user.us_suscription_status
            ) {
              let isAdmin = false;
              let isSeo = false;
              if (user?.roles) {
                user.roles.forEach(rol => {
                  if (rol.rol_name === ROLES.administrador) isAdmin = true;
                  if (rol.rol_name === ROLES.seo) isSeo = true;
                });
              }

              if (data.data.us_suscription_status === "free") {
                verifyFreeUserExpDate(user.us_id).then(response => {
                  if (response.data.isExpired) {
                    logoutAction(unCheckUser)(dispatch);
                  }
                });
              }

              // TODO: Cambiar esto por un solo dispatch para roles en general
              if (isAdmin) {
                dispatch({
                  type: ACT_IS_ADMIN_TRUE,
                });
              } else {
                dispatch({
                  type: ACT_IS_ADMIN_FALSE,
                });
              }

              if (isSeo) {
                dispatch({
                  type: ACT_IS_SEO_TRUE,
                });
              } else {
                dispatch({
                  type: ACT_IS_SEO_FALSE,
                });
              }

              localStorage.setItem("U", JSON.stringify(user));
              dispatch({
                type: ACT_USER_CHANGE,
                props: {
                  user: data.data,
                  token: token,
                  isAdmin: isAdmin,
                  isSeo: isSeo,
                },
              });
            } else {
              if (user.us_suscription_status === "free") {
                verifyFreeUserExpDate(user.us_id).then(response => {
                  if (response.data.isExpired) {
                    logoutAction(unCheckUser)(dispatch);
                  }
                });
              }
              let isAdmin = false;
              let isSeo = false;
              if (user?.roles) {
                user.roles.forEach(rol => {
                  if (rol.rol_name === ROLES.administrador) isAdmin = true;
                  if (rol.rol_name === ROLES.seo) isSeo = true;
                });
              }

              if (isAdmin) {
                dispatch({
                  type: ACT_IS_ADMIN_TRUE,
                });
              } else {
                dispatch({
                  type: ACT_IS_ADMIN_FALSE,
                });
              }

              if (isSeo) {
                dispatch({
                  type: ACT_IS_SEO_TRUE,
                });
              } else {
                dispatch({
                  type: ACT_IS_SEO_FALSE,
                });
              }

              dispatch({
                type: ACT_USER_CHANGE,
                props: {
                  user: user,
                  token: token,
                  checked: true,
                  isAdmin: isAdmin,
                  isSeo: isSeo,
                },
              });
            }

            Firebase.onSnapshotPayment(user, payment => {
              dispatch({ type: ACT_USER_CHANGE, props: { payment: payment } });
            });
            callback && callback();
          })
          .catch(e => {
            console.log("ERROR LOGIN", e);
            logoutAction(unCheckUser)(dispatch);
          });
      } else {
        getUserData(userProvided)
          .then(data => {
            const user = data.data;
            if (data.data.us_suscription_status === "free") {
              verifyFreeUserExpDate(user.us_id).then(response => {
                if (response.data.isExpired) {
                  logoutAction(unCheckUser)(dispatch);
                }
              });
            }

            localStorage.setItem("U", JSON.stringify(user));
            localStorage.setItem("is", "checked");
            let isAdmin = false;
            let isSeo = false;
            if (user?.roles) {
              user.roles.forEach(rol => {
                if (rol.rol_name === ROLES.administrador) isAdmin = true;
                if (rol.rol_name === ROLES.seo) isSeo = true;
              });
            }

            if (isAdmin) {
              dispatch({
                type: ACT_IS_ADMIN_TRUE,
              });
            } else {
              dispatch({
                type: ACT_IS_ADMIN_FALSE,
              });
            }

            if (isSeo) {
              dispatch({
                type: ACT_IS_SEO_TRUE,
              });
            } else {
              dispatch({
                type: ACT_IS_SEO_FALSE,
              });
            }

            dispatch({
              type: ACT_USER_CHANGE,
              props: {
                user: user,
                token: token,
                checked: true,
                isAdmin: isAdmin,
                isSeo: isSeo,
              },
            });
            Firebase.onSnapshotPayment(user, payment => {
              dispatch({ type: ACT_USER_CHANGE, props: { payment: payment } });
            });
          })
          .catch(e => {
            return logoutAction(unCheckUser)(dispatch);
          });
      }
      checkUser && checkUser();
    });
  };
}

export function logoutAction(cb) {
  return dispatch => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("U");
    Firebase.unsubscribePayment();
    dispatch({
      type: ACT_USER_CHANGE,
      props: {
        user: null,
        payment: null,
        token: null,
        devices: [],
        checked: true,
      },
    });
    cb && cb();
    if (localStorage.getItem("is") !== "checked") {
      history.push("/");
    }
  };
}

export function refreshTokenAction(userProvided, cb) {
  return dispatch => {
    let forceRefresh = true;
    userProvided.getIdToken(forceRefresh).then(token => {
      localStorage.setItem("Authorization", token);
      dispatch({
        type: ACT_USER_CHANGE,
        props: { token: token },
      });
      cb && cb();
    });
  };
}
